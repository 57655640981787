@import "../../style/variables.scss";

.equipment-card-small {
  border: none;
  background-color: $white-2;
  // background-color: $blue;
  // background-color: $gray-1;
  border-left: 0.5rem solid $blue;
  border-right: 0.5rem solid $white-2;
  border-radius: 0.25rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.55);

  .card-img-top {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .card-body {
    background-color: $white-2;
    // background-color: $gray-1;
    padding: 0.1em;
    text-align: center;
    width: 225px;

    h6 {
      color: $blue;
      font-weight: bold;
    }
  }
}
