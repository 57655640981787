@import "../../style/variables.scss";

.service-card {
  border: none;
  border: 2px solid $blue;
  height: 100%;
  width: 350px;
  max-width: 350px;
  border-radius: 0.25rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;

  p.sub-header {
    font-size: 12px;
    line-height: 2.33em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 500;
    color: #565969;
    display: inline-block;
    margin: 0 0 5px;
  }

  .card-img-top {
    height: 250px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    object-fit: cover;
    object-position: bottom;
  }

  .card-body {
    background-color: white;

    .card-title {
      color: $blue;
      font-weight: bold;
      font-family: Lato;
    }

    .list-group-item {
      background-color: white;
    }
  }
}

.vertical-services {
  .service-card {
    width: 100%;
  }
}

.service-header {
  color: $blue;
  text-align: left;
  font-weight: bold;
}
