@import "../../style/variables.scss";

.cjs-timeline,
.vertical-timeline-element-content {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}
.vertical-timeline-element-content {
  background: $white-2;
}

.logo {
  width: 100%;
}
