@import "../../style/variables";

.contact-card-col {
  margin-top: 20px;
}
.contact-card {
  border-top: 0.5rem solid $blue;
  height: 100%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);

  a {
    margin: 0;
  }
  h4 {
    color: $blue;
  }
  h6 {
    color: $blue-4;
    margin-bottom: 0;
  }

  a.logo {
    display: inline-block;
    margin: 0;
    width: 35px;
    height: 35px;
    transition: all 0.2s linear;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
    // margin-right: 4px;

    &.facebook-anchor {
      background-image: url("../../images/Logos/facebook.png");
    }

    &.instagram-anchor {
      background-image: url("../../images/Logos/instagram.png");
    }

    &.linkedin-anchor {
      background-image: url("../../images/Logos/linkedin.png");
    }
  }
}
