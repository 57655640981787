.aligner {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

img.success-logo {
  width: 150px;
  vertical-align: middle;
}

.tile-row {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
