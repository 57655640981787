@import "../../style/variables.scss";

.MuiPaper-root,
.modal-content {
  background: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(10px);
}

.step-icon {
  height: 30px;
  width: 30px;
}

.close {
  position: absolute;
  right: 10px;
  margin: 10px;
  z-index: 2;
}

.header {
  color: $gray-1;
}

.modal-header {
  border-bottom: 2px solid $blue;
  img {
    width: 120px;
  }
}

.modal-footer {
  border-top: 2px solid $blue;
}

.MuiTypography-body2 {
  font-family: Montserrat !important;
}
