@import "../../../style/variables.scss";

.photo-carousel {
  img.carousel-img {
    object-fit: contain;
    height: 500px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background: $blue;
    // background: rgba(0, 0, 0, 0.75);
    // filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3))
    //   drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
  }

  .img-wrapper-outer {
    width: 100%;
    text-align: center;
  }

  .img-wrapper-inner {
    display: inline-block;
    position: relative;
    height: 500px;
  }
}
