@import "../../style/variables.scss";

.vertical-timeline-element--crane {
  .vertical-timeline-element-content {
    border-top: 0.5rem solid $blue;
  }
}

.vertical-timeline-element--telehandler {
  .vertical-timeline-element-content {
    border-top: 0.5rem solid $blue;
  }
}

.vertical-timeline-element--location {
  .vertical-timeline-element-content {
    border-top: 0.5rem solid $orange;
  }
}

.vertical-timeline-element--truck {
  .vertical-timeline-element-content {
    border-top: 0.5rem solid $red-1;
  }
}

.vertical-timeline-element-subtitle {
  color: $gray-1 !important;
}
