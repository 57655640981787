@import "../../../style/variables.scss";

.modal-content {
  background-color: $white-2 !important;
}

.error-mesg {
  color: $red-1;
  font-weight: bold;
}

.error-mesg {
  a {
    font-weight: normal;
  }
}
.step-icon {
  height: 30px;
  width: 30px;
}

.logo-guy {
  height: 200px;
  width: auto;
}

.MuiTypography-body2 {
  font-family: Montserrat !important;
}
