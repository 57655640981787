@import "../../style/variables.scss";

.equipment-card {
  border: none;
  border-top: 0.5rem solid $red-1;
  border-radius: 0.25rem;
  width: 310px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);

  background: rgba($gray-2, 0.8) !important;
  backdrop-filter: blur(10px);

  .card-body {
    width: 310px;
  }

  .card-title {
    color: $blue;
    font-weight: bold;
    margin-top: 0.75rem;
  }

  .card-subtitle {
    color: $gray-4;
  }

  .card-img-top {
    width: 280px;
    height: 140px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 0.75rem;
    min-height: 36px;
  }
}
