@import "../../style/variables";

.cjs-jumbotron {
  background-image: url("../../images/crane-boom2.jpg");
  background-position: 0% 75%;
  background-attachment: scroll;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  height: 90vh;

  &.background-fixed {
    background-attachment: fixed;
  }

  @media (max-height: 500px) {
    height: 90vh;
  }

  @media (min-width: 720px) and (min-height: 700px) {
    height: 100vh;
    background-attachment: fixed;
  }

  padding: 0px;
  position: relative;

  .main-content {
    padding-top: 18vh;
    padding-top: 33vh;

    @media (max-height: 440px) {
      padding-top: 33vh;
    }

    @media (min-width: 720px) and (min-height: 720px) {
      padding-top: 33vh;
    }

    h1 {
      color: $white;
      font-family: Barlow;
      margin-bottom: 0px;

      @media (max-width: 500px) and (orientation: portrait),
        (max-height: 500px) and (orientation: landscape) {
        font-size: 2rem;
      }

      @media (min-width: 501px) and (orientation: portrait),
        (min-height: 501px) and (orientation: landscape) {
        font-size: 3rem;
      }

      @media (min-width: 768px) and (orientation: portrait),
        (min-height: 768px) and (orientation: landscape),
        (min-width: 768px) and (orientation: portrait) {
        font-size: 3.5rem;
      }

      @media (min-width: 769px) and (orientation: portrait),
        (min-height: 769px) and (orientation: landscape),
        (min-width: 769px) and (min-height: 600px) and (orientation: landscape) {
        font-size: 4.5rem;
      }

      font-weight: bold;
      letter-spacing: 0.3rem;
    }

    h2 {
      color: $blue-5;
      font-weight: normal;
      font-size: 1.5rem;

      @media (max-width: 500px) and (orientation: portrait),
        (max-height: 500px) and (orientation: landscape) {
        font-size: 1rem;
        line-height: 1.75rem;
      }

      @media (min-width: 501px) and (orientation: portrait),
        (min-height: 501px) and (orientation: landscape) {
        font-size: 1.25rem;
        line-height: 2rem;
      }

      @media (min-width: 768px) and (orientation: portrait),
        (min-height: 768px) and (orientation: landscape),
        (min-width: 768px) and (min-height: 600px) and (orientation: landscape) {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }

      letter-spacing: 0.1rem;
      line-height: 2.5rem;
    }

    .button-col {
      display: flex;
      align-items: center;
    }

    .btn-lg {
      @media (max-width: 500px) and (orientation: portrait),
        (max-height: 500px) and (orientation: landscape) {
        font-size: 0.5rem;
      }

      @media (min-width: 501px) and (orientation: portrait),
        (min-height: 501px) and (orientation: landscape) {
        font-size: 0.9rem;
      }

      @media (min-width: 720px) and (orientation: portrait),
        (min-height: 720px) and (orientation: landscape) {
        font-size: 1.2rem;
      }
    }
  }
}

.cjs-jumbotron.equipment {
  background-image: url("../../images/EquipmentJumbo.jpeg");
  background-position: 0% 55%;
  background-attachment: scroll;
  height: 35vh;
  @media (max-height: 500px) {
    height: 60vh;
  }

  .main-content {
    padding-top: 18vh;

    @media (max-height: 500px) {
      padding-top: 33vh;
    }

    h1 {
      @media (max-width: 500px) and (orientation: portrait),
        (max-height: 500px) and (orientation: landscape) {
        font-size: 2rem;
      }

      @media (min-width: 501px) and (orientation: portrait),
        (min-height: 501px) and (orientation: landscape) {
        font-size: 3rem;
      }

      @media (min-width: 768px) and (orientation: portrait),
        (min-height: 768px) and (orientation: landscape) {
        font-size: 3.5rem;
      }

      @media (min-width: 769px) and (orientation: portrait),
        (min-height: 769px) and (orientation: landscape) {
        font-size: 4.5rem;
      }
    }
  }
}

.overlay {
  backdrop-filter: blur(3px);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0)
  );
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}
