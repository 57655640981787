@import "../../style/variables.scss";

.job-card {
  .close {
    position: absolute;
    right: 0px;
  }
  border: none;
  background-color: rgba(0, 0, 0, 0);
  // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  // margin-bottom: 50px;
  width: 100%;

  > .card-body {
    background-color: rgba(0, 0, 0, 0);
  }

  p {
    font-size: 0.75rem;
  }
}
