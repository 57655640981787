@import "../../../style/variables.scss";

.job-title {
  font-weight: bold;
}

.job-info {
  p {
    font-size: 0.75rem;
  }
}
