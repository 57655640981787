@import "../../style/variables.scss";

.spec-wrapper {
  text-align: center;
}

.spec-card {
  background-color: $white-2;
  border: none;
  border-left: 0.5rem solid $orange;
  border-right: 0.5rem solid $white-2;
  border-radius: 0.25rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.55);
  margin-left: auto;
  margin-right: auto;

  p {
    font-size: 0.75rem;
    margin: 0px;
    font-weight: bold;
    color: $gray-3;
  }

  .spec-value {
    color: $orange;
    font-weight: bold;
    margin-bottom: 0px;
  }

  > .card-body {
    background-color: $white-2;
    padding: 0.5em;
  }
}
